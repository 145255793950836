<template>
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">请为您的角色设置名字</h5>
        <div class="mt-2">
              <span class="p-float-label ">
                <InputText id="username" type="text" v-model="name" style="width:100%" />
                <label for="username">角色名称</label>
            </span>
        </div>
      
        <template #footer>
            <Button label="确定" @click="comfirm" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>
</template>

<script>
import { defineComponent,ref,watch } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
export default defineComponent({
    name:"setNameModal",
    emits: ["createRoleEvent"],
    props: [
        'showNameModal'
    ],
    setup(props,{emit}) {
         console.log("是否显示弹框",props)
        let display=ref(props.showNameModal);
        let name=ref("");
        console.log("是否显示弹框",display)
        //console.log("tests");
        watch(
            () => props.showNameModal,
            () => {
                display.value=props.showNameModal;
            }
        );
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        const comfirm=()=>{
           
            emit('createRoleEvent',name.value);
            display.value=false;
            return 
        }
        return {
            
            name,
            comfirm,
            display
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
