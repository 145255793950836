<template>
  
	<!-- <Button label="购买体力" icon="iconfont icon-jiaose-" 
    class="mr-2 w_10" ></Button> -->
    <Button label="购买宝石维护天数" 
    class="mr-2 w_10" @click.stop="buyGemDayModalOpen()"></Button>
    <Dialog header="" v-model:visible="display" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">请输入你购买的体力值数量</h5>
        <div class="mt-2">
              <span class="p-float-label ">
                <InputText id="username" type="Number" v-model="psAmount" style="width:100%" />
                <label for="username">购买数量</label>
            </span>
        </div>
      
        <template #footer>
            <Button label="确定" @click="comfirm" icon="pi pi-check" 
            class="p-button-secondary"/>
        </template>
    </Dialog>

     <Dialog header="宝石维护天数套餐" v-model:visible="isGemDayModalShow" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" >
        <p class="mb-4 text-center">请选择你想购买的宝石维护天数套餐</p>
        <p class="text-danger mb-4 text-center d-none"  style="font-size:xx-small">
            (至少购买七天)</p>
        <div class="listBox" v-for="(item,index) in GemstoneList"
        @click="buyGemDay(item)">
            <div class="list text-center">
                {{item.gemstoneContract.day.toNumber()}} 天 需
                <span class="ml-2">
                    {{MathPrice(Number(ethers.utils.formatEther(item.afterDiscountAmount)))}} {{tokenName}}
                </span>
            </div>
        </div>
        <!-- <div class="mt-2">
              <span class="p-float-label ">
                <InputText type="Number" v-model="GemDayAmount" style="width:100%" />
                <label for="username">购买天数</label>
            </span>
        </div> -->
      
        <!-- <template #footer>
            <Button label="确定" @click="isConfirmBuyGemModal==true" icon="pi pi-check" 
            class="p-button-secondary" :disabled="GemDayAmount<7"/>
        </template> -->
    </Dialog>

     <Dialog header="确认购买" v-model:visible="isConfirmBuyGemModal" 
    :breakpoints="{'960px': '75vw'}" :style="{width: '30vw'}" 
    :modal="true" @update=update>
        <h5 class="mb-4 text-center">
            是否确认购买<span class="text-danger">
                {{GemDayAmount}}
            </span>天的宝石维护天数
        </h5>
         <p class="mb-0 text-center">
            是否确认花费<span class="text-dangw_10er">{{GemBuyAmount}}</span>XYJ 购买天的宝石维护天数
        </p>
       <hr>
        <Button label="确定" @click="ComfirmBuyGemDay" 
            class="p-button-secondary w_10 mt-0"/>
        
    </Dialog>

</template>

<script>
import { defineComponent,ref,watch,getCurrentInstance } from 'vue';
import Dialog from 'primevue/dialog'; 
import InputText from 'primevue/inputtext';
import * as logicBoss from "../../wallet/LogicBoss";
import { useStore } from "vuex";
import {getGemstoneContract} from "../../wallet/LogicGem";
export default defineComponent({
    name:"buyBtnModal",
    emits: ["getRoleListEvent"],
    props: [
        'roleInfo'
    ],
    setup(props,{emit}) {
        const store = useStore();
        let isConfirmBuyGemModal=ref(false);
         const roleInfo=ref(props.roleInfo)
        let display=ref(props.showNameModal);
        let psAmount=ref("");
       let GemstoneList=ref();
       let isGemDayModalShow=ref(false);
       let GemDayAmount=ref(7);
       let GemBuyAmount=ref(0);
       const { proxy } = getCurrentInstance(); 
        //console.log("tests");
        watch(
            () => props.showNameModal,
            () => {
                display.value=props.showNameModal;
            }
        );
        watch(()=>display.value,()=>{
            if(!display.value){
                emit('close',display.value);
            }
           
        })
        const buyPsModalOpen=()=>{
             console.log("传过来的角色信息",roleInfo.value)
             display.value=true
        }
        const comfirm=()=>{
               display.value=false;
           logicBoss.rps((roleInfo.value.role.itemId).toNumber(),psAmount.value).then(res=>{
               console.log("购买体力值返回",res);
                store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
               emit('getRoleListEvent');
           }).catch(error=>{
                 store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 console.log("交易失败")
             })
         
            return 
        }

        //点击btn购买第一步
        const buyGemDay=(item)=>{
            isGemDayModalShow.value=false;
            isConfirmBuyGemModal.value=true;
            GemDayAmount.value=item.gemstoneContract.day.toNumber();
            GemBuyAmount.value=proxy.ethers.utils.formatEther(item.afterDiscountAmount,2)
        }
        const ComfirmBuyGemDay=()=>{
             
             isConfirmBuyGemModal.value=false;
             logicBoss.gemMaintenance((roleInfo.value.role.itemId).toNumber(),GemDayAmount.value).then(res=>{
               console.log("购买天数返回",res);
                 store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                emit('getRoleListEvent');
           }).catch(error=>{
                 store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 console.log("交易失败")
             })
        }

        const buyGemDayModalOpen=()=>{
            isGemDayModalShow.value=true;
        }

        //获取套餐详情
        getGemstoneContract((roleInfo.value.role.itemId).toNumber()).then(res=>{
            console.log("获取套餐详情",res);
            GemstoneList.value=res;
        })

  
        return {
            buyPsModalOpen,
            buyGemDayModalOpen,
            isGemDayModalShow,
            ComfirmBuyGemDay,
            psAmount,
            GemDayAmount,
            comfirm,
            display,
            GemstoneList,
            isConfirmBuyGemModal,
            buyGemDay,
            GemBuyAmount
        }
        
    },
    components : {
        Dialog,
        
        InputText
    }
    
})
</script>
<style lang="scss" scoped>
.listBox{
    padding: 10px;
    background: $themeColor;
    color:$text-themeBg-color;
    border-radius: $radisIconbtn;
    margin-bottom: 10px;
    font-weight: bold;
    cursor: pointer;
    &:hover{
        transform: scale(1.05);
    }
}
</style>