<template>
 <Button label="创建悟空角色" icon="iconfont icon-jiaose-" 
    class="mr-2" @click="OpenCreateName(1)"></Button>
    <Button label="创建猪八戒角色" icon="iconfont icon-jiaose-" 
    class="mr-2 mt-2" @click="OpenCreateName(2)"></Button>
    <Button label="创建沙悟净角色" icon="iconfont icon-jiaose-" 
    class="mr-2 mt-2" @click="OpenCreateName(3)"></Button>
    	<setNameModal :showNameModal="showNameModal" 
        @close="closeNameModal" @createRoleEvent="createRoleEvent"/>
</template>


<script>
import { defineComponent,ref,getCurrentInstance} from 'vue';
import setNameModal from "../../components/Modal/setNameModal.vue";
import {getBlbalance} from "../../wallet/tokenAuto";
import {createRoleApi} from "../../wallet/LogicRole";
import * as bossApi from "../../wallet/LogicBoss";
import { useStore } from "vuex";

export default defineComponent({
    name:"createRole",
    emits: ["getRoleListEvent"],
    components: {
		setNameModal,
		
	},
    setup(props, {
      emit
    }){
        const store = useStore();
        let showNameModal=ref(false);
        let roleNumber=ref(1);
        const { proxy } = getCurrentInstance(); 
        //创建角色名字
        const OpenCreateName=(type)=>{
            roleNumber.value=type;
            showNameModal.value=true;
        }
        //创建角色
        const createRoleEvent=(name)=>{
            let setName;
            showNameModal.value=false;
             if(!name){
                 if(roleNumber.value==1){
                     setName='monkey'
                 }else if(roleNumber.value==2){
                     setName='pig'
                 }else if(roleNumber.value==3){
                     setName='monk'
                 }
               
             }else{
                 setName=name;
             }
             
        
          
             createRoleApi(roleNumber.value,setName).then(res=>{
                
                 store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 getBlbalance();
                 emit('getRoleListEvent')
             }).catch(error=>{
                 store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)-1);
                 console.log("交易失败")
             })
        }

        const closeNameModal=()=>{
            showNameModal.value=false;
        }
        //检查授权情况   
        const isBossTokenAuth=()=>{
             bossApi.isAllowance().then(res=>{
                 console.log("宝石组件调用检查授权",res);
                 if(res>0){
                      store.commit("UpdateIsBossTokenAuth",true)
                     return true
                 }else{
                     store.commit("UpdateIsBossTokenAuth",false)
                     return false;
                 }
             })
        }
       // isBossTokenAuth()
         const authTips=ref("当前暂未授权BossLogic合约,点击授权");
         const isAuthNow=ref(false);
         const approveBoss=()=>{
            isAuthNow.value=true;
            authTips.value="正在唤起钱包授权";
            bossApi.toApprove().then(res=>{
                
                isAuthNow.value=false;
                if(res){
                     
                    store.commit("UpdateIsBossTokenAuth",true)
                    return true
                }else{
                    authTips.value="当前暂未授权宝石合约,点击授权3";
                    store.commit("UpdateIsBossTokenAuth",false)
                    return false;
                }
            }).catch(error=>{
                authTips.value="当前暂未授权宝石合约,点击授权1";
                 store.commit("UpdateIsBossTokenAuth",false)
                    return false;
            })
        }
        return{
            authTips,
            showNameModal,
            isAuthNow,
          createRoleEvent,
          OpenCreateName,
          closeNameModal,
          approveBoss,
        }
    },
    
    
})
</script>
