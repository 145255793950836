import {
    ethers
} from "ethers";


import store from "@/store";





  
  let ContractAddress;
  let Abi;
  async function start(){
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let res1= await signer.getChainId();
    let net_address='address'
      if (res1=='1337') {
        net_address='address'
      }else{
        net_address='address_bsc'
      }
  
     
      const { default: Contract } = await import(`@/wallet/${net_address}/LogicGemstone.logicGemstoneAddress.json`);
      ContractAddress = Contract.address;
      Abi = Contract.abi;
  
  
  };
import { approve, allowance} from "./tokenAuto";
export async function isAllowance() {
    let isAllowance=await allowance(ContractAddress);
   
    return isAllowance;
}
export async function toApprove() {
    let isApprove=await approve(ContractAddress,Abi);
    return isApprove;
}

export async function extractGemsApi() {
    await start();
    let max=9999;
    let min=1000;
    let num=Math.floor(Math.random()*(max-min))+min;
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    console.log("抽取宝石生成四位数",num)
    let res = await myContract.extractGems(num);
    store.commit("UpdateWaitCount", Number(store.getters.getWaitCount)+1);
    if(res.wait()){
        await res.wait();
       
      }
    if(res){
        
        return res;
    }else{
    return false
    }
        
}

export async function getGemsList() {
    await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    console.log("封装方法调用getGemsList1")
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    console.log("封装方法调用getGemsList2")
    let res = await myContract.getGems();
    
    if(res){
        
        return res;
    }else{
      return false
    }
      
}

export async function getGemsLuckDrawPrice() {
    await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
    
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
  
    let res = await myContract.getGemsLuckDrawPrice();
    res=Math.floor(ethers.utils.formatEther(res,6) * 10000) / 10000;
    if(res){
        
        return res;
    }else{
      return false
    }
      
}

//获取宝石维护费用列表
export async function getGemstoneContract(roleid) {
    await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
  
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    
    let res = await myContract.getGemstoneContract(roleid);
    
    if(res){
        
        return res;
    }else{
      return false
    }
      
}

//销毁宝石
export async function destroyGemstone(itemId) {
    await start();
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner();
  
    let myContract =await new ethers.Contract(ContractAddress, Abi, signer);
    
    let res = await myContract.destroyGemstone(itemId);
    
    if(res){
        
        return res;
    }else{
      return false
    }
      
}
